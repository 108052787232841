import { useEffect } from 'react';
import styled from 'styled-components';
import { SadTear } from 'styled-icons/fa-regular';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { withTracker } from '@/tracker';
import Bugsnag from '@/bugsnag';
import Section from '@/components/section';
import Title from '@/components/title';
import SubTitle from '@/components/sub-title';
import Button from '@/components/button';

const FourOhFour = () => {
  const router = useRouter();

  useEffect(() => {
    Bugsnag.notify(new Error(`Page not found: ${router.asPath}`));
  }, [router.asPath]);

  return (
    <Section centerContent>
      <Title>404</Title>
      <SubTitle>Well this is embarrassing! We can&apos;t seem to find that page.</SubTitle>
      <Link href="/" passHref>
        <a>
          <Button primary>Back to Home page</Button>
        </a>
      </Link>
    </Section>
  );
};

export default withTracker(FourOhFour);
